import { List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { FaDivide } from "react-icons/fa6";
 
export const  SideBar = props =>  {
  
  return <div className="p-3 mt-[3rem] h-100svh w-full  p-4 shadow-xl bg-[#151515]">
    <List>
      <ListItem>
        <ListItemPrefix>
          {props?.icon}
        </ListItemPrefix>
        {props.label}
        <Typography  className="text-gray-200">
          {'Overview'}
        </Typography>
      </ListItem> 
      <ListItem>
        <ListItemPrefix>
          {props?.icon}
        </ListItemPrefix>
        {props.label}
        <Typography  className="text-gray-200">
          {'Calendar'}
        </Typography>
      </ListItem> 
      <ListItem>
        <ListItemPrefix>
          {props?.icon}
        </ListItemPrefix>
        {props.label}
        <Typography className="text-gray-200">
          {'Teams'}
        </Typography>
      </ListItem> 
      
      <ListItem>
        <ListItemPrefix>
          {props?.icon}
        </ListItemPrefix>
        {props.label}
        <Typography className="text-gray-200">
          {'Highlights'}
        </Typography>
      </ListItem> 
      
      <ListItem>
        <ListItemPrefix>
          {props?.icon}
        </ListItemPrefix>
        {props.label}
        <Typography  className="text-gray-200">
          {'League'}
        </Typography>
      </ListItem> 
      
      <hr class="h-px my-8 bg-gray-700 border-0" />

      <ListItem>
        <ListItemPrefix>
          {props?.icon}
        </ListItemPrefix>
        {props.label}
        <Typography  className="text-gray-200">
          {'Transfers'}
        </Typography>
      </ListItem> 
      
      <ListItem>
        <ListItemPrefix>
          {props?.icon}
        </ListItemPrefix>
        {props.label}
        <Typography className="text-gray-200">
          {'Tournaments'}
        </Typography>
      </ListItem> 
    
    </List>
  </div>
}