import React from "react";
import { Card, CardHeader, Typography } from "@material-tailwind/react";
import Img from "../assets/highlight.jpeg";

export const HighlighCard = () => {
  
  return <Card color="transparent" shadow={false} className="w-full bg-[#1F1F1F] my-4 rounded-md">
      <CardHeader
        color="transparent"
        floated={false}
        shadow={false}
        className="mx-2 flex gap-4 p-2 items-center"
      >

      <img alt="highlight" src={Img} width={110} className="rounded-md"/>

      <div className="flex w-full flex-col gap-0.5">
        <div className="flex items-center justify-between">
          <Typography className="text-white text-xs font-semibold">
            Wits League: Pirates vs Wits University 
          </Typography>
        </div>
        <Typography className="text-gray-300 mt-3 text-xs">22 OCT 2023</Typography>
      </div>

      </CardHeader>
  </Card>
}