import { useRef } from 'react';
import  { Input } from '@material-tailwind/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export const SearchComponent = props => {

  const inputRef = useRef(null);

  const placeholder = 'Quick Search'

  return <div className='relative flex w-full gap-2 md:w-max'>
    <Input
      ref={ inputRef }
      type='search'
      className='pr-20'
      icon={<MagnifyingGlassIcon className='h-5 w-5' />}
      placeholder={ props.placeholder ? props.placeholder : placeholder }
      value={props.filter.state.globalFilter || undefined}
      onChange={e => {props.setFilter(e.target.value) }}
      containerProps={{
        className: 'min-w-[288px]',
      }}
    />

  </div>
}