import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { AppRoutes } from "./routes/AppRoutes";

export const App = props => {

  const queryClient = new QueryClient()

  return <QueryClientProvider client={queryClient}>
    <AppRoutes />
  </QueryClientProvider>
}


export default App;
