import React from 'react';
import { FcAlphabeticalSortingAz, FcAlphabeticalSortingZa } from 'react-icons/fc';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { SearchComponent } from './SearchComponent';
import { Typography } from '@material-tailwind/react';

export const Table = props => {

  const columns = props.columns
  const data = props.data

  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter

  } = tableInstance

  const globalFilter = { state }

  const renderHeader = () => {
    return <thead className='transparent'>
      {
        headerGroups.map( headerGroup =>
          <tr {...headerGroup.getHeaderGroupProps()}>
            {
              headerGroup.headers.map( column => <th {...column.getHeaderProps(column.getSortByToggleProps())} color='blue-gray'
                className='font-normal leading-none opacity-70 py-4 tracking-wide font-sembold text-start border-b'>
                <Typography variant='small' className='font-semibold'>
                  { column.render('Header') }
                </Typography>
                <span>
                  {column.isSorted ? (column.isSortedDesc ? <FcAlphabeticalSortingAz/> : <FcAlphabeticalSortingZa/> ) : '' }
                </span>
              </th>)
            }
          </tr>)
      }
    </thead>
  }

  const renderFooter = () => {

    return <tfoot className='transparent'>
      {
        footerGroups.map(footerGroup =>
          <tr {...footerGroup.getFooterGroupProps()}>
            {
              footerGroup.headers.map(column =>
                <th {...column.getFooterProps()} color='blue-gray'
                className='font-normal leading-none opacity-70 p-4 tracking-wide font-sembold'>
                  <Typography variant='small'>{column.render('Footer')}</Typography>
                </th>)
            }
          </tr>)
      }
    </tfoot>
  }

  const renderBody = () => {
    return <tbody {...getTableBodyProps()}>
      {
        rows.map( row => {
          prepareRow(row)
          return <tr className='even:bg-[#151515]' {...row.getRowProps() } onClick={ props.callback ? () => props.callback(row.original) : null}>
            {row.cells.map( cell => <td className='py-4 px-2 border-b border-gray-900  cursor-pointer whitespace-nowrap' {...cell.getCellProps()}>
              {cell.render('Cell')}
              </td>
              )}
          </tr>

        })
      }
    </tbody>
  }

  const renderGFilter = () => {

    if (props.showFilter) {
      return <div className='overflow-auto'>
        <SearchComponent filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
    }
    return null
  }

  const renderTable = () => {

    return <div className='overflow-auto'>
      <table {...getTableProps()} className='w-full min-w-max table-auto text-start mt-6'>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </table>
    </div>
  }
  return <div className='overflow-auto'>
    { renderGFilter() }
    { renderTable() }
  </div>
}
