import React from "react" 
import { Chip, Typography } from "@material-tailwind/react"
import Img from "../assets/upcoming-1.png"

export const UpcomingMatch = props => {
  
  return <div color="transparent" className="h-80 rounded-md w-full bg-[#B02820]">
    <div className="grid grid-cols-[1fr_1fr]">
      <div className="px-10 pt-10">
      <Typography className="text-sm font-semibold">
        {"UPCOMING MATCH"}
      </Typography>
      <div className="flex mt-5">
        <div>
          
          <div className="py-4"> 
            <Typography className="text-sm">
              {"Fri 17 June 2024"} | {"Wits University Supper League"}
            </Typography>
          </div>

          <div className="my-4 flex">
            <Typography className="text-white">{"Madritus FC"}</Typography>
            <Chip size="xs" value={"15:00"} className="p-[0.01px] mx-5 px-5 rounded-md"/>
            <Typography className="text-white">{"Matsika Shooting Stars"}</Typography>
          </div>

        </div>
          
      </div>
      </div>
      <img alt="upcoming-1" src={Img} width={"100%"}/>
    </div>
  </div>
}