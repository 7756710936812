import { Typography } from "@material-tailwind/react"
import React from "react"
import { ChartComponent } from "./ChartComponent"
 
const options = {
  responsive: true,
  plugins: { 
  	legend: { 
  		labels: {
        font: { size: 8 },
        padding: 25,
        usePointStyle: true
      },
  		position: 'bottom',
  		align: 'center'
  	},
  }
};

const data = {
  labels: ['Wins ', 'Draw  ', 'Losses'],
  datasets: [
    {
      data: [1, 1, 1],
      backgroundColor: ['green','orange', 'red'],
      borderColor: ['green', 'orange', 'red'],
      borderWidth: 1,
    },
  ],
};

export const GamesOverview = props => {
  
  return <div color="transparent" className="w-full bg-[#151515] shadow-md rounded-md">
    <div className="flex justify-between p-4">
      <div>
        <Typography className="text-sm font-semibold">
          {"GAME OVERVIEW"}
        </Typography>
      </div>

      <div>
        <Typography className="text-sm">{"Last 20 games"}</Typography>
      </div>
    </div>
    <ChartComponent data={data} options={options}/>

  </div>
}