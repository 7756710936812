import { Chip, Typography } from "@material-tailwind/react";
import React from "react";
import { Table } from "./Table";

const DATA = [
  {
    "league_id": "123",
    "team_id": "1235",
    "team_name": "Vondwe XI Bullets",
    "position": 1,
    "points": 4,
    "matches_played": 3,
    "won_matches": 1,
    "draw_matches": 1,
    "lost_matches": 1,
    "goals_for": 2,
    "goals_against": 3,
    "goal_different": 2,
    "season": "",
    "updated": "2024-01-25T17:39:09.596Z"
  },
  {
    "league_id": "123",
    "team_id": "1235",
    "team_name": "Wits Business school FC",
    "position": 2,
    "points": 2,
    "matches_played": 3,
    "won_matches": 0,
    "draw_matches": 2,
    "lost_matches": 0,
    "goals_for": 2,
    "goals_against": 3,
    "goal_different": 2,
    "season": "",
    "updated": "2024-01-25T17:39:09.596Z"
  }
]

const COLUMNS = [
  {
    Header: 'TEAM',
    accessor: 'position',
    id: 0,
    Cell: ({row}) => {
      const position = row.original.position
      return <div className="w-[12px] flex justify-center items-center rounded-full">
        <Chip value={position} className="rounded-full" color="blue"/>
      </div> 
    }
  },
  {
    Header: '',
    accessor: 'team_name',
    id: 1,
    Cell: ({row}) => {
      const team = row.original.team_name
      return <Typography>{team}</Typography>
    }
  },
  {
    Header: 'MP',
    id: 2,
    accessor: 'matches_played',
    Cell: ({row}) => {
      const mp = row.original.matches_played
      return <Typography>{mp}</Typography>
    }
  },
  {
    Header: 'W',
    id: 3,
    accessor: 'won_matches',
    Cell: ({row}) => {
      const won = row.original.won_matches
      return <Typography>{won}</Typography>
    }
  },
  {
    Header: 'D',
    id: 4,
    accessor: 'draw_matches',
    Cell: ({row}) => {
      const draw = row.original.draw_matches
      return <Typography>{draw}</Typography>
    }
  },
  {
    Header: 'L',
    id: '5',
    accessor: 'lost_matches',
    Cell: ({row}) => {
      const lost = row.original.lost_matches
      return <Typography>{lost}</Typography>
    }
  },
  {
    Header: 'GA',
    id: '6',
    accessor: 'lost_matches',
    Cell: ({row}) => {
      const lost = row.original.lost_matches
      return <Typography>{lost}</Typography>
    }
  },
  {
    Header: 'GF',
    id: '7',
    accessor: 'lost_matches',
    Cell: ({row}) => {
      const lost = row.original.lost_matches
      return <Typography>{lost}</Typography>
    }
  },
  {
    Header: 'GD',
    id: '8',
    accessor: 'lost_matches',
    Cell: ({row}) => {
      const lost = row.original.lost_matches
      return <Typography>{lost}</Typography>
    }
  },
  {
    Header: 'Points',
    id: 9,
    accessor: 'points',
    Cell: ({row}) => {
      const p = row.original.points
      return <Typography className="text-green-300">{p}</Typography>
    }
  },
]

export const League = props => {

  return <div className="p-4 rounded-md bg-[#151515]">
    <div className="flex justify-between">
      <Typography variant="lead" className="text-sm font-semibold">
        LEAGUE STANDING
      </Typography>
      <small>{"2024/2025"}</small>
    </div>
    <Table data={DATA} columns={COLUMNS} />
  </div>
}