import React from 'react';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip
} from 'chart.js';
import { Bar, Bubble, Doughnut, Line, Pie } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

export const ChartComponent = props => {


  const renderChart = ( name, options, data ) => {
    switch (name) {
      case 'pie':
        return <Pie options={options} data={data}/>
      case 'bar':
        return <Bar options={options} data={data}/>
      case 'line':
        return <Line options={options} data={data}/>  
      case 'bubble':
        return  <Bubble options={options} data={data}/>  
      default:
        return <Doughnut options={options} data={data}/>
    }
  }

  return <div className='h-[240px] w-full rounded-md flex justify-center items-center'>
    { renderChart(props.name, props.options, props.data) }
  </div>
}
