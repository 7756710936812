import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { APP_ROUTES } from '../config/config';
import Container from '../screens/team/Dashboard';
import { Header } from '../components/Header';
// import useToken from '../useToken';

export const AppRoutes = () => {

  return <Router>
    <Header />
    <Routes>
      <Route path={ APP_ROUTES.ROOT } element={ <Container /> } />
    </Routes>
  </Router>
}