import React from "react";
import {
  Navbar,
  Typography
} from "@material-tailwind/react";
 
export const  Header = props => {
 
  return <Navbar className="sticky bg-[#1F1F1F] top-0 z-10 h-max max-w-full rounded-none border-none px-4 py-2 lg:px-8 lg:py-4">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography className="text-white mr-4 cursor-pointer py-1.5 font-medium">
          University of Witwatersrand
        </Typography>
        <div className="flex items-center gap-4"></div>
      </div>
    </Navbar>
}