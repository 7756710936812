import React from "react";
import { SideBar } from "../../components/SideBar";
import { UpcomingMatch } from "../../components/UpcomingMatch";
import { GamesOverview } from "../../components/GamesOverview";
import { Chip, Typography } from "@material-tailwind/react";
import { HighlighCard } from "../../components/HighlightCard";
import { LastMatch } from "../../components/LastMatch";
import { Player } from "../../components/Player";
import { League } from "../../components/Leauge";


const Dashboard = props => {

  return <div className="w-full h-screen grid grid-cols-[max-content_auto] grid-rows-[5fr_1fr]">
    <SideBar />
    
    <div className="mt-[3rem]">
      
      <div className="container grid grid-cols-[2fr_1fr] gap-4">
        
        <div>
          <UpcomingMatch />
          <div className="grid grid-cols-2 gap-4 my-10">
            <GamesOverview />
            <Player />
          </div>
          <League />    
        </div>

        <div className="grid gap-4">  
        
          <LastMatch />
          
          <div className="bg-[#151515] p-3 rounded-md">
            
            <div className="flex justify-between">
              <Typography>{"HIGHLIGHTS"}</Typography>
              <Chip value={"See All"} color="gray" className="rounded-full px-3"/>
            </div>
            <HighlighCard />
            <HighlighCard />
            <HighlighCard />
            <HighlighCard />
          </div>
        
        </div>

      </div>

    </div>

    {/* <div className="col-span-2 bg-[#151515] p-3">
      Carousel
    </div> */}

  </div>
}

export default Dashboard