import React from "react"
import { Progress, Typography } from "@material-tailwind/react"
import { FaBaseball } from "react-icons/fa6";

 
export const LastMatch = props => {
  
  return <div color="transparent" className="rounded-md w-full bg-[#151515] shadow-md p-6">
    <Typography className="text-sm font-semibold">
      {"LAST MATCH"}
    </Typography>
    
    <div className="flex justify-between items-center py-4">
      
      <div className="p-2">  
        <FaBaseball size={25} color="green" className="mb-2"/> 
        <Typography className="py-2">{"UTD"}</Typography>
      </div>

      <div className="flex justify-around w-[40%] items-center">
        <Typography variant="h2">{"2"}</Typography>-<Typography variant="h2">{"0"}</Typography>
      </div>
        
      <div className="p-2">
        <FaBaseball size={25} color="blue" className="mb-2 flext justify-center"/>
        <Typography className="py-2 text-center">{"MSS"}</Typography>
      </div>

    </div>
    
    
    <div className="flex justify-between items-center py-4">
      <Typography variant="lead" color="green">{"64%"}</Typography>
      <Typography className="text-gray-400">{"Possesion"}</Typography>
      <Typography variant="lead">{"36%"}</Typography>
    </div>

    <Progress value={64} variant="gradient" color="blue"/>
    
    <div className="mt-4">
      <Typography className="text-white text-xs">M Mathoho 88'</Typography>
      <Typography className="text-white text-xs">J Khanye 61'</Typography>
    </div>

  </div>
}