import React from "react"
import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react"
import Img from "../assets/player.jpeg"

export const Player = props => {
  
  return <div className="p-2 bg-[#151515] rounded-md">
    
    <div className="flex justify-between px-3">
      <Typography className="text-sm font-semibold">{"PLAYER OF THE MONTH"}</Typography>
      <small>{"FEB"}</small>
    </div>

    <Card className="rounded-none bg-[#151515]">
      <CardHeader floated={false} className="border-[0.1px] border-red-500">
        <img alt="palyer1" src={Img}/>
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="white" className="mb-2">
          {"Andile Dlamini"}
        </Typography>
      </CardBody>
    </Card>
  </div>
}